<template>
  <section class="projects fade">
    <div class="inline-grid-container container-padding top-block">
      <LandingTitleBlock
        :title="$t('news')"
        :isFilter="false"
        :tag="tag"
      />
    </div>

    <Loader v-if="loading" />

    <div
      class="news-list inline-grid-container container-padding"
    >

      <NewsItem
        v-for="el in localNews"
        :key="el.id"
        :news="el"
        margin
        max-width-full
        @openNews="openNews"
      />
    </div>

    <button
      type="button"
      class="yellow-btn"
      v-if="!!news.next"
      @click="getMoreNews"
    >
      {{$t('showMore')}}
    </button>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import LandingTitleBlock from '../../components/LandingTitleBlock.vue';
import NewsItem from '../../components/NewsItem.vue';
import Loader from '../../components/UI/Loader.vue';

export default {
  name: 'News',

  data () {
    return {
      loading: true,
      page: 1,
    }
  },

  computed: {
    ...mapState('news', ['news']),
    ...mapState('news', ['tag']),
    localNews () {
      return this.news.results
    },
  },

  methods: {
    ...mapActions('news', ['getNews']),
    ...mapMutations(['setTag']),
    ...mapMutations('news', ['resetNews']),
    openNews (id) {
      this.$router.push(`/one-news/${id}`)
    },
    async getMoreNews () {
      this.page++
      await this.getNews({ page: this.page, pageSize: 8 })
    },
    async deleteTag () {
      this.loading = true
      await this.getNews({})
      await this.setTag(null)
      this.loading = false
    },
  },

  async mounted () {
    this.resetNews()
    if (this.tag) {
      await this.getNews({ tag: this.tag, page: this.page, pageSize: 8 })
    } else {
      await this.getNews({ page: this.page, pageSize: 8 })
    }
    this.loading = false
  },

  components: { LandingTitleBlock, NewsItem, Loader },
};
</script>

<style scoped lang="scss">
/*.news {
  margin: 80px auto 140px;
  min-height: 100vh;
  max-width: 1600px;
}*/

.top-block {
  width: 100%;
}

.tags {
  display: flex;
  margin-top: 20px;
  .v-chip {
    margin-right: 10px;
  }
}

.news-list {
  margin: 40px 0 0;
}

.news-list.inline-grid-container {
  grid-template-columns: repeat(4, minmax(0, 340px));
}

@media screen and (max-width: 1100px) {
  .news-list {
    margin-top: 48px;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .news-list.inline-grid-container {
    grid-template-columns: repeat(3, minmax(0, 340px));
  }
}

@media (min-width: 500px) and (max-width: 900px) {
  .news-list.inline-grid-container {
    grid-template-columns: repeat(2, minmax(0, 440px));
  }
}

@media (max-width: 499px) {
  .news-list.inline-grid-container {
    grid-template-columns: repeat(1, minmax(0, 440px));
  }
}
</style>
